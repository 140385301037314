<template>
  <v-app>
    <v-main>

      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {  },
/*   beforeCreate() {
    if (this.$cookies.get("lg") == "1") return;
    this.$fire({
      type: "question",
      title: "Password",
      text: "Unesite password da udjete!",
      backdrop: `
    rgba(0,0,0,1)
  `,
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      confirmButtonText: "Ulaz",
    }).then((r) => {
      if (typeof r.value == "undefined") {
        window.location.href = "http://balkanac.net/";
      }
      if (r.value == "Emil12345") {
        this.$cookies.set("lg", "1");
        return;
      } else {
        window.location.href = "http://balkanac.net/";
      }
    });
  }, */
  mounted() {
    this.$nextTick(() => {
      const script = document.createElement("script");
      script.setAttribute("id", "usercentrics-cmp");
      script.setAttribute("async", "");
      script.setAttribute("data-eu-mode", "true");
      script.setAttribute("data-settings-id", "TUidTmf62An-_e");
      script.setAttribute(
        "src",
        "https://app.eu.usercentrics.eu/browser-ui/latest/loader.js"
      );
      document.head.appendChild(script);
    });
  },
  data: () => ({
    //
  }),
};
</script>

<style>
.footer {
  position: absolute;
  bottom: 0;

  /* And if you want the div to be full-width: */
  left: 0;
  right: 0;
}
</style>
