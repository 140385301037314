import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import VueSimpleAlert from "vue-simple-alert";
import Dropdown from "vue-simple-search-dropdown";
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSmoothScroll from 'vue2-smooth-scroll'
import router from './router'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
})
Vue.config.silent=true
Vue.config.productionTip = false
Vue.use(Dropdown);
Vue.use(VueCookies);
Vue.use(VueSimpleAlert);
Vue.use(VueAxios, axios);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.$cookies.config('2h')
Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
