import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "register" */ '../components/MainPage.vue'),
    },
    {
        path: '/cuvanjepodataka',
        name: 'cuvanjepodataka',
        component: () => import(/* webpackChunkName: "register" */ '../components/CuvanjePodataka.vue'),
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import(/* webpackChunkName: "register" */ '../components/ImpressumShow.vue'),
    }
    ,
    {
        path: '/agb',
        name: 'agb',
        component: () => import(/* webpackChunkName: "register" */ '../components/AGBShow.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  
export default router

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}
router.beforeEach((to, from, next) => {
    if(!hasQueryParams(to) && hasQueryParams(from)){ 
        return next( { name:to.name, query:from.query } ) 
    }    
    
    next()
})
