import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        old_service: process.env.VUE_APP_OLD_MY_ACCOUNT,
        service: process.env.VUE_APP_MY_ACCOUNT,
        api_gateway: process.env.VUE_APP_GATEWAY,
        account_service: process.env.VUE_APP_ACCOUNT_SERVICE,
        utils: process.env.VUE_APP_UTILS_SERVICE,
        client: process.env.VUE_APP_CLIENT,
        version: process.env.VUE_APP_VERSION,
        customer_portal: process.env.VUE_APP_CUSTOMER_PORTAL,
        brm: process.env.VUE_APP_BRM,
        bdl: process.env.VUE_APP_BDL,
        access: process.env.VUE_APP_ACCESS,
        administration_app: process.env.VUE_APP_ADMIN,
        sso_domain: process.env.VUE_APP_SSO_DOMAIN,
        captcha: process.env.VUE_RECAPTHA,
        build: process.env.VUE_APP_BUILD,
        revision: process.env.VUE_APP_REVISION,
        telco: process.env.VUE_APP_TELCO,

        step2_range: '',
        step2_item:'',
        step1_lat:0,
        step1_lng:0,
        login:0,
    }
})